type Dependency = string | number | null

let lastId: string | null = null
let lastDependency: Dependency = null

function getUniqId() {
  const timestamp = Date.now()
  const randomComponent = Math.random().toString(36).substr(2, 9)

  return `${timestamp}-${randomComponent}`
}

export default function (dependency: Dependency = null): string {
  // If no dependency is provided, always generate a new ID
  if (dependency === null) {
    return getUniqId()
  }

  // Generate a new ID if dependency changed or no last ID exists
  if (dependency !== lastDependency || lastId === null) {
    lastId = getUniqId()
    lastDependency = dependency
  }

  return lastId
}
