import { AxiosResponse } from 'axios'
import { RouteLocationNormalized } from 'vue-router'

import { HttpStatus } from '@/app/types/api/config'

import Store from '@/store'

import service from '@/app/api/resources/service'
import ClientResponseError from '@/app/errors/ClientResponseError'

export default function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  if (to.name === from.name) {
    return Promise.resolve(Store.state.service.serverStatus)
  }

  if (Store.getters['service/isServerMaintaining']) {
    return Promise.reject(
      ClientResponseError.createSimpleError(
        'Server down',
        HttpStatus.ServiceUnavailable,
      ),
    )
  }

  function handleServerStatusFetchSuccess(response: AxiosResponse<unknown>) {
    Store.commit('service/changeServerStatus', response.status)

    return Promise.resolve(response.status)
  }

  return service
    .serverStatus()
    .then(handleServerStatusFetchSuccess)
    .catch(() => Promise.reject())
}
