export default {
  // single
  single: {
    // order
    order: {
      success: 'Payment successful',
      canceled: 'Payment canceled',
      expired: 'Payment expired',
    },
    // donation
    donation: {
      success: 'Donation was successfully made!\nThank you!',
      canceled: 'Donation canceled',
      expired: 'Donation expired',
    },
  },
  // recurrent
  recurring: {
    success: 'Payment has been made',
    successDescription:
      'You can manage your subscriptions from the INXY Dashboard page.',
    canceled: 'Payment canceled',
    expired: 'Payment expired',
  },
}
