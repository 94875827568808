import { DictionaryResponse } from '@/app/types/api/config'
import {
  Organizations,
  OrganizationsResponse,
} from '@/app/types/api/resources/organizations'

import client from '@/app/api/client'

export default {
  fetchOrganizations(): Promise<DictionaryResponse<OrganizationsResponse[]>> {
    return client.get(Organizations.Organizations)
  },
}
