import { WritableComputedRef } from 'vue'
import { VueI18n } from 'vue-i18n'

import {
  I18NLanguage,
  I18NLanguageName,
  I18NLocale,
} from '@/app/types/app/i18n'

import cookies from '@/plugins/cookies'

import env from '@/app/env'

export class I18nDecorator {
  readonly #i18n: VueI18n
  readonly #availableLocales: I18NLocale[]
  readonly #DefaultLocale: I18NLocale
  #locale: WritableComputedRef<I18NLocale>

  constructor(i18n: VueI18n, defaultLocale: I18NLocale) {
    this.#i18n = i18n
    /**
     * Locale is reactive, but in i18n types it is not described for some reason
     */
    this.#locale = this.#i18n
      .locale as unknown as WritableComputedRef<I18NLocale>
    this.#availableLocales = this.#i18n.availableLocales as I18NLocale[]
    this.#DefaultLocale = defaultLocale
  }

  getLanguageCode(language: string): I18NLocale | string {
    return language.split('-')[0]
  }

  getBrowserLocale(): I18NLocale | undefined {
    const languages = navigator.languages as I18NLocale[]

    const locale = languages.find((language) => {
      const languageCode = this.getLanguageCode(language)
      const i18nLocaleCode = this.getLanguageCode(this.#locale.value)

      return (
        this.#availableLocales.includes(language) ||
        languageCode === i18nLocaleCode
      )
    })

    return locale === undefined
      ? undefined
      : (this.getLanguageCode(locale) as I18NLocale)
  }

  getLocaleCookie(): I18NLocale | undefined {
    const localeFromCookie = cookies.get(
      env.cookieNameI18n,
    ) as I18NLocale | null

    return localeFromCookie === null
      ? undefined
      : (localeFromCookie as I18NLocale)
  }

  setLocale(language: I18NLocale) {
    this.#locale.value = language
    cookies.set(env.cookieNameI18n, this.#locale.value, '365d', '/', env.host)
  }

  setApplicationLocale() {
    const localeFromCookie = this.getLocaleCookie()

    if (localeFromCookie === undefined) {
      const browserLocale = this.getBrowserLocale()

      this.setLocale(browserLocale ?? this.#DefaultLocale)
    } else if (localeFromCookie !== this.#locale.value) {
      const cookieLocaleCode = this.getLanguageCode(
        localeFromCookie,
      ) as I18NLocale
      const localeToSet = this.#availableLocales.includes(cookieLocaleCode)
        ? cookieLocaleCode
        : this.#DefaultLocale

      this.setLocale(localeToSet)
    }
  }

  get locale(): WritableComputedRef<I18NLocale> {
    return this.#locale
  }

  #getLanguageName(locale: I18NLocale): I18NLanguageName {
    switch (locale) {
      case I18NLocale.En:
        return I18NLanguageName.English
      case I18NLocale.Es:
        return I18NLanguageName.Spanish
    }
  }

  get availableLocales(): I18NLocale[] {
    return this.#availableLocales
  }

  get availableLanguages(): I18NLanguage[] {
    return this.#availableLocales.map((locale) => {
      return {
        code: locale,
        name: this.#getLanguageName(locale),
      }
    })
  }

  get defaultLocale(): I18NLocale {
    return this.#DefaultLocale
  }
}
