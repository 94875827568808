import cookies from '@/plugins/cookies';
import Store from '@/store';
import UserStoreService from '@/store/services/UserStoreService';

import env from '@/app/env';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const token = cookies.get(env.cookieName);
    const hasUser = Store.getters['users/hasUser'];

    if (hasUser || token === null) {
      return resolve();
    }

    const userService = new UserStoreService();

    return userService.fetchUser().then(resolve).catch(reject);
  });
}
