export enum Verification {
  CheckVerification = '/api/verification',
}

export enum VerificationType {
  Individual = 'individual',
  Business = 'company',
}

export type SumsubToken = `_abc-${string}`

export enum VerificationStateResult {
  Pending = 'pending',
  Success = 'success',
  Blocked = 'blocked',
}

export enum VerificationApprovalStatus {
  Waiting = 'waiting_for_approval',
  Approved = 'approved',
  Declined = 'declined',
}

export interface VerificationResponse {
  type: null | VerificationType
  result: VerificationStateResult
  access_token: null | SumsubToken
  manual_approval_status: VerificationApprovalStatus
}
