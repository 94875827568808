import cookies from '@/plugins/cookies';
import Store from '@/store';
import OrganizationsStoreService from '@/store/services/OrganizationsStoreService';

import env from '@/app/env';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    const token = cookies.get(env.cookieName);

    if (state.organizations.currentOrganizationId || token === null) {
      return resolve();
    }

    const orgService = new OrganizationsStoreService();

    return orgService.fetchOrganizations().then(resolve).catch(reject);
  });
}
