export default function (number: number, fractionDigits: number): number {
  const numberAsString = number.toString();
  const isExponential = numberAsString.includes('e');

  if (isExponential || Number.isInteger(number)) {
    return number;
  }

  const [integer, fractions] = numberAsString.split('.');
  const truncatedFractions = fractions.substring(0, fractionDigits);
  const truncatedNumberAsString = [integer, truncatedFractions].join('.');

  return parseFloat(truncatedNumberAsString);
}
