import { AxiosRequestHeaders, AxiosResponse } from 'axios'

import { DictionaryResponse } from '@/app/types/api/config'
import {
  AuthApi,
  AuthFormData,
  AuthResponse,
  Balance,
  BalanceParams,
  EmailApi,
  SignupFormData,
  UsersApi,
  UsersMeResponse,
} from '@/app/types/api/resources/users'

import client from '@/app/api/client'

export default {
  fetchMe(): Promise<AxiosResponse<UsersMeResponse>> {
    return client.get(UsersApi.Me)
  },

  fetchBalances(
    params?: BalanceParams,
  ): Promise<DictionaryResponse<Balance<string>[]>> {
    return client.get(UsersApi.Balances, { params })
  },

  login(data: AuthFormData): Promise<AxiosResponse<AuthResponse>> {
    return client.post(AuthApi.Login, data)
  },

  logout(): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.Logout)
  },

  signup(data: SignupFormData): Promise<AxiosResponse<AuthResponse>> {
    return client.post(AuthApi.Signup, data)
  },

  verifyEmail(headers?: AxiosRequestHeaders): Promise<AxiosResponse<void>> {
    return client.post(EmailApi.EmailVerify, null, { headers })
  },
}
