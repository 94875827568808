export default {
  twoStepVerification: {
    title: 'Verificación en dos pasos',
    description: 'Ingresa el código de autenticación que te hemos enviado a',
    below: 'debajo',
    resendCode: 'Reenviar código',
    requestCode: 'Solicita otro código en',
    checkEmailMessage:
      'El código de confirmación se ha enviado a tu correo electrónico.',
  },
  cancelIntent: {
    title: '¿Estás seguro?',
    reallyWantDelete:
      '¿Realmente deseas cancelar el pago actual y volver a la página *{merchant}*?',
    deleteWarn:
      '¡No canceles si ya se enviaron fondos!\n¡Se perderá el dinero!',
  },
  authModal: {
    login: {
      title: 'Inicio de sesión en la cuenta de INXY Payments',
      formTip: '¿Todavía no tienes una cuenta de INXY Payments?',
      formLink: 'Regístrate',
      formButton: 'Entrar',
      forgotPassword: '¿Olvidaste la contraseña?',
    },
    signup: {
      title: 'Crear una cuenta de INXY Payments',
      formTip: '¿Ya estás registrado?',
      formLink: 'Entrar',
      formButton: 'Regístrate',
      usernameCantBeChanged:
        'El nombre de usuario no se puede cambiar más tarde.',
    },
  },
  paymentRules: {
    title: '¡Atención! Por favor, asegúrese de que:',
    rules: {
      cryptocurrency: `Usted envía: *{cryptoCode}*`,
      blockchain: `Usted usa: *{blockchain}*`,
      countdown: `Debe completar el pago en: *{countdown}*`,
      session:
        'La billetera "Enviar a" es válida solo para esta sesión de pago.',
    },
    note: 'Tenga en cuenta que el incumplimiento de estas reglas puede resultar en la pérdida de sus fondos.',
    actionButton: 'Estoy de acuerdo',
  },
}
