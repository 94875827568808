import { AxiosError } from 'axios'
import { Notify } from 'quasar'
import { QNotifyCreateOptions } from 'quasar/dist/types/index'

import { ErrorResponse, HttpStatus, TwoFactorAuth } from '@/app/types/api/config'
import { ServiceEndpoint } from '@/app/types/api/resources/service'

import i18n from '@/plugins/i18n'
import Store from '@/store'

export enum InterceptorNotifyName {
  TwoFactorAuthorization = 'two-factor-authorization',
  Maintenance = 'maintenance',
}

export default class ErrorResponseInterceptorNotify {
  readonly #error: AxiosError<ErrorResponse>
  readonly #messages: string[]

  constructor(error: AxiosError<ErrorResponse>, messages: string[]) {
    this.#error = error
    this.#messages = messages
  }

  get #mainErrorMessage(): string {
    return this.#messages[0]
  }

  get #responseStatus(): HttpStatus | undefined {
    return this.#error.response?.status
  }

  get notifyName(): InterceptorNotifyName | null {
    const accessForbidden = this.#responseStatus === HttpStatus.Forbidden
    const twoFactorMessage = this.#mainErrorMessage === TwoFactorAuth.ConfirmationMessage

    if (accessForbidden && twoFactorMessage) {
      return InterceptorNotifyName.TwoFactorAuthorization
    } else if (Store.getters['service/isServerMaintaining']) {
      return InterceptorNotifyName.Maintenance
    }

    return null
  }

  get options(): QNotifyCreateOptions | null {
    return this.notifyName === null
      ? {
        message: this.#mainErrorMessage,
        type: 'negative',
      }
      : null
  }

  #twoFactorAuth() {
    Notify.create({
      message: i18n.global.t('modals.twoStepVerification.checkEmailMessage'),
      type: 'positive',
    })
  }

  #maintenance() {
    if (this.#error.config.url) {
      Notify.create({
        message: i18n.global.t('errors.serviceUnavailable'),
        type: 'negative',
      })
    }
  }

  create() {
    switch (this.notifyName) {
      case InterceptorNotifyName.TwoFactorAuthorization:
        this.#twoFactorAuth()
        break
      case InterceptorNotifyName.Maintenance:
        if (this.#error.config.url !== ServiceEndpoint.ServerStatus) {
          this.#maintenance()
        }
        break
    }
  }
}
