import { Locales } from '@/app/types/app/translation/locales'

import preciseNumTrunc from '@/app/helpers/preciseNumTrunc'
import { regExTrailingZeros } from '@/app/regularExpressions'

type SignDisplay = 'always' | 'never' | 'auto'

export default {
  parseFloat(amount: string): number {
    if (amount === '') {
      return 0
    }

    return parseFloat(amount.replace(',', '.'))
  },

  format(number: number, options: Intl.NumberFormatOptions): string {
    if (typeof number !== 'number' || isNaN(number)) {
      throw TypeError("Value isn't a number")
    }

    return new Intl.NumberFormat(Locales.Ru, {
      minimumFractionDigits: 2,
      ...options,
    })
      .format(number)
      .replace(',', '.')
      .replace(regExTrailingZeros, '')
  },

  fiatToHuman(number: number, signDisplay: SignDisplay = 'auto'): string {
    return this.format(number, { maximumFractionDigits: 2, signDisplay })
  },

  toHuman(number: number, signDisplay: SignDisplay = 'auto'): string {
    const cryptoMaxFractionDigits = 8

    /**
     * For case when currency have more than 8 fraction digits
     */
    if (parseFloat(number.toFixed(cryptoMaxFractionDigits)) === 0) {
      return '0'
    }

    const truncatedNumber = preciseNumTrunc(number, cryptoMaxFractionDigits)

    return this.format(truncatedNumber, {
      maximumFractionDigits: cryptoMaxFractionDigits,
      signDisplay,
    })
  },

  autoFormat(number: number, isCrypto: boolean): string {
    return isCrypto ? this.toHuman(number) : this.fiatToHuman(number)
  },
}
