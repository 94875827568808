export default {
  twoStepVerification: {
    title: 'Two-step verification',
    description: "Enter the authentication code we've sent to",
    below: 'below',
    resendCode: 'Resend code',
    requestCode: 'Request another code in ',
    checkEmailMessage: 'Confirmation code was sent to your email',
  },
  cancelIntent: {
    title: 'Are you sure?',
    reallyWantDelete:
      'Do you really want  to cancel current payment and return back to *{merchant}* page?',
    deleteWarn:
      'Do not cancel if any funds were already sent!\nMoney will be lost!',
  },
  authModal: {
    login: {
      title: 'INXY Payments account login',
      formTip: 'Don’t have an INXY Payments account yet?',
      formLink: 'Sign up',
      formButton: 'Log in',
      forgotPassword: 'Forgot password?',
    },
    signup: {
      title: 'Create INXY Payments account',
      formTip: 'Already registered?',
      formLink: 'Log in',
      formButton: 'Sign up',
      usernameCantBeChanged: 'Username can not be changed later',
    },
  },
  paymentRules: {
    title: 'Attention! Please make sure that:',
    rules: {
      cryptocurrency: `You send: *{cryptoCode}*`,
      blockchain: `You use: *{blockchain}*`,
      countdown: `You must complete the payment within: *{countdown}*`,
      session: 'The "Send to" wallet is valid for this payment session only.',
    },
    note: 'Please mind that breaching these rules may result in loss of your funds.',
    actionButton: 'I agree',
  },
}
