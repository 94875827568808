import { createStore } from 'vuex'

import { GlobalState } from '@/app/types/store/store'

import organizations from '@/store/modules/organizations'
import service from '@/store/modules/service'
import session from '@/store/modules/session'
import users from '@/store/modules/users'
import verification from '@/store/modules/verification'

export default createStore<GlobalState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: { users, session, verification, service, organizations },
})
