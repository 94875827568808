import { CryptoCurrency, Currency } from '@/app/types/app/currency'

export enum UsersApi {
  Me = '/api/users/me',
  Balances = '/api/balances',
}

export enum AuthApi {
  Login = '/api/auth/login',
  Logout = '/api/auth/logout',
  Signup = '/api/auth/register',
}

export enum EmailApi {
  EmailVerify = '/api/email/verify',
}

export enum UserStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export interface User {
  id: number
  email: string
  username: string
  email_verified_at: string | null
  onboarding_completed_at: string | null
  default_currency_id: number
  default_currency: Currency
  analytics_id: string
  /**
   * Always string, but if user didn't confirm his identity string is empty
   */
  first_name: string
  last_name: string
  status: UserStatus
}

export interface Balance<TAmount = number> {
  amount: TAmount
  pendingReceived: TAmount
  fiatAmount: TAmount
  cryptocurrency: CryptoCurrency
  currency: Currency
}

export interface BalanceParams {
  currency_id: number
}

export interface UsersMeResponse {
  user: User
  balances: Balance[]
}

export enum SignupType {
  Paygate = 'payment_gateway',
  Auth = 'auth',
}

export interface AuthFormData {
  email: string
  password: string
}

export interface SignupFormData extends AuthFormData {
  username: string
  password_confirmation?: string
  registration_type: SignupType
}

export interface AuthResponse {
  access_token: string
  user: User
}
