import { VueI18n } from 'vue-i18n'
import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { HttpStatus } from '@/app/types/api/config'
import { I18NLocale } from '@/app/types/app/i18n'
import { RouteName } from '@/app/types/app/routes'

import i18n from '@/plugins/i18n'

import { I18nDecorator } from '@/app/decorators/I18nDecorator'
import ClientResponseError from '@/app/errors/ClientResponseError'
import SimpleClientError from '@/app/errors/SimpleClientError'

import router from '@/router'

export function resolveRoute(name: RouteName) {
  return router.resolve({ name, params: { locale: I18NLocale.En } })
}

export function resolveServicePageRoute(
  error: Error | ClientResponseError | SimpleClientError,
  toRouteName: RouteName,
) {
  if (error instanceof ClientResponseError) {
    switch (error.status) {
      case HttpStatus.ServiceUnavailable:
        if (toRouteName === RouteName.Maintenance) {
          return
        }

        return resolveRoute(RouteName.Maintenance)
      default:
        return
    }
  }

  return
}

export function resolveI18nRoute(
  to: RouteLocationNormalized,
): RouteLocationRaw | undefined {
  /**
   * i18n.global is valid VueI18n instance, but description is a bit differs as I can see
   */
  const i18nDecorator = new I18nDecorator(
    i18n.global as unknown as VueI18n,
    I18NLocale.En,
  )

  i18nDecorator.setApplicationLocale()

  const toLocale = to.params.locale ?? ''
  const isDefaultLocaleRoute =
    i18nDecorator.locale.value === i18nDecorator.defaultLocale &&
    toLocale === ''

  if (isDefaultLocaleRoute && to.name !== undefined) {
    return
  }

  const routeLocale = i18nDecorator.getLanguageCode(toLocale as string)
  const localeFound = i18nDecorator.availableLocales.includes(routeLocale as I18NLocale)

  if (localeFound === false) {
    const route = router.resolve(i18nDecorator.locale.value + to.fullPath)
    const routeResolved = route.name !== undefined
    const routeToProceed = routeResolved
                           ? route
                           : {
        name: RouteName.NotFound,
        params: { locale: i18nDecorator.locale.value },
      }

    return routeToProceed
  }

  const toLocaleDiffersFromI18n = toLocale !== i18nDecorator.locale.value

  if (toLocaleDiffersFromI18n) {
    return {
      ...to,
      params: {
        ...to.params,
        locale: i18nDecorator.locale.value,
      },
    }
  }
}
