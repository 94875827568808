export default {
  // single
  single: {
    // order
    order: {
      awaiting: 'Awaiting payment',
      processing: {
        name: 'Processing',
        title: 'Your payment is being processed',
        description:
          "You've paid for this purchase, but we need some time to process it.",
      },
      partial: {
        name: 'Partial payment',
        title: 'This payment was made only partially',
        description:
          'Please press the Pay the remaining amount button for a full payment or contact your merchant directly.',
        warning:
          'DO NOT SEND MONEY AGAIN TO YOUR PREVIOUS WALLET, THIS WILL RESULT IN ITS LOSS',
        actionButton: 'Pay the remaining amount',
      },
      success: {
        name: 'Success',
        title: 'Your payment was successfully processed',
        description: 'Thank you for the purchase!',
      },
      cancelled: {
        name: 'Cancelled',
        title: 'Your payment has been cancelled!',
      },
      expired: {
        name: 'Timed out',
        title: 'Time has run out!',
        description:
          "Your session time has run out and the payment has failed. You can return to the merchant's page and make the payment anew.",
      },
      partialExpired: {
        name: 'Expired',
        title: 'This payment was made partially but the time has run out!',
        description:
          'Your session time has run out and the payment has failed.\nBut as it was partially paid please contact your merchant\ndirectly for further instructions.',
      },
      failed: {
        name: 'Failed',
        title: 'An error occurred during payment!',
        description:
          'Please contact your merchant directly for further instructions.',
      },
      illegal: {
        name: 'Illegal',
        title: 'Closed. Illegal Payment',
        description:
          'Transaction flagged during KYT review. Funds have been frozen pending further investigation.',
      },
    },
    // donation
    donation: {
      processing: {
        title: 'Your donation is being processed',
        description:
          "You've made donation, but we need some time to process it.",
      },
      success: {
        title: 'Your donation was successfully processed',
        description: 'Thank you for the donation!',
      },
      cancelled: {
        title: 'Your donation has been cancelled!',
      },
      expired: {
        title: 'Time has run out!',
        description:
          'Your session time has run out and the payment has failed.\nYou can return to donation page and make the payment anew.',
      },
      illegal: {
        name: 'Illegal',
        title: 'Closed. Illegal Payment',
        description:
          'Transaction flagged during KYT review. Funds have been frozen pending further investigation.',
      },
    },
  },
  // recurring
  recurring: {
    success: {
      title: 'Your balance is successfully topped up!',
      description:
        'Payment has been made.\nYou can manage your subscriptions from the INXY Dashboard page.',
    },
  },
}
