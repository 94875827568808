import { createRouter, createWebHistory } from 'vue-router'

import { RouteName } from '@/app/types/app/routes'

import Store from '@/store'
import VerificationStoreService from '@/store/services/VerificationStoreService'

import ClientResponseError from '@/app/errors/ClientResponseError'

import organizations from '@/router/middleware/organizations.global'
import serverStatus from '@/router/middleware/serverStatus.global'
import user from '@/router/middleware/user.global'
import {
  resolveI18nRoute,
  resolveRoute,
  resolveServicePageRoute,
} from '@/router/resources/routerResources'
import routes from '@/router/resources/routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const toRoute = resolveI18nRoute(to)
  const toName = to.name as RouteName

  try {
    await serverStatus(to, from).then(user).then(organizations)

    if (Store.getters['users/hasUser']) {
      const verificationService = new VerificationStoreService()

      await verificationService.checkVerification()
    }

    const isNextRouteBlockingPage = toName === RouteName.AccessRestricted
    const userBlocked = Store.getters['users/isUserBlocked']

    if (
      toName === RouteName.Maintenance ||
      (userBlocked === false && isNextRouteBlockingPage)
    ) {
      if ((toName as RouteName) === RouteName.NotFound) {
        return
      }

      return resolveRoute(RouteName.NotFound)
    }

    return toRoute
  } catch (errorEvent) {
    const error = errorEvent as Error | ClientResponseError

    return resolveServicePageRoute(error, toName)
  }
})

export default router
