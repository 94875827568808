export default {
  standardServerError: 'An error occurred. Refresh page and try again',
  required: 'This field can not be empty',
  emailTemplate: 'Please enter a valid email address',
  passTemplate:
    'Password must be at least 8 characters with 1 uppercase letter, 1 lowercase and 1 number',
  usernameTemplate:
    'This username is invalid. It must be 5–15 symbols long and cannot contain special characters, except for low line',
  minSymbols: 'Minimum {symbol} symbols',
  maxSymbols: 'Maximum {symbol} symbols',
  integers: 'Only integers allowed',
  topupAmount: 'Choose topup amount',
  notEnoughFunds: 'Insufficient funds in your account',
  wrongCoinType: "Wrong coin type. You can't send the transaction",
  requestWasDenied:
    'We are using another blockchain. Please change blockchain.',
  transactionDenied: 'Transaction was denied by user.',
  transactionRejected:
    'Transaction was rejected for unknown reason. Please try again later.',
  permissionsPending:
    'You currently have an active MetaMask window open. Please either close your active MetaMask window or continue using it. Thank you.',
  nonceTooLow: {
    message: 'Nonce is too low, try to change it manually. Follow the next',
    linkLabel: 'article.',
  },
  tronLinkLocked: 'TronLink locked. Please log in',
  tronLinkNotFound: 'To use this option install TronLink extension',
  tronAddressNotFound: 'Tron address was not found',
  contractNotFound: 'Contract not found',
  networkNotSupported:
    'Selected network {userNetwork} not supported. Please select {network}',
  paymentOptionUnavailable:
    'Sorry, this payment option is currently unavailable for your account.',
  cryptoNotAvailable:
    'Unfortunately, at the moment, payment with this cryptocurrency is not available. \n' +
    'We recommend selecting another cryptocurrency from the list or trying again in a minute.\n',
}
