import { AxiosError, AxiosRequestConfig } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestHeaders,
} from '@/app/types/api/config'

import cookies from '@/plugins/cookies'
import i18n from '@/plugins/i18n'
import Store from '@/store'
import getUniqId from '@/utils/getUniqId'

import env from '@/app/env'

import router from '@/router'

const AppProjectName = 'INXY-PAY'

// getToken

export function getToken() {
  const token = cookies.get(env.cookieName)

  return token ? 'Bearer ' + token : ''
}

// setRequestHeaders

export function setRequestHeaders(config: AxiosRequestConfig) {
  const orgId = Store.state.organizations.currentOrganizationId

  if (config.headers) {
    if (config.headers[RequestHeaders.Localization] === undefined) {
      config.headers[RequestHeaders.Localization] = i18n.global.locale.value
    }

    if (orgId) {
      config.headers[RequestHeaders.OrganizationId] = orgId
    }
  }

  // set path and page-id headers
  const path = router.currentRoute.value.path

  if (config.headers && path) {
    config.headers[RequestHeaders.PageRequestId] = getUniqId(path)
    config.headers[RequestHeaders.PagePath] = path
    config.headers[RequestHeaders.ProjectName] = AppProjectName
  }

  return config
}

// getErrorMessages

export function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message =
    error.response?.data?.message ?? i18n.global.t('errors.standardServerError')
  const errors = error.response?.data?.errors

  switch (error?.response?.status) {
    case HttpStatus.PaymentRequired:
      return [i18n.global.t('errors.paymentFailed')]
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message]
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')]
    default:
      return [message]
  }
}
