import { BackendResponse } from '@/app/types/api/config'
import { ServiceEndpoint } from '@/app/types/api/resources/service'

import client from '@/app/api/client'

export default {
  serverStatus(): BackendResponse<unknown> {
    return client.get(ServiceEndpoint.ServerStatus)
  },
}
