export default {
  twoFactorAction: 'Confirmar pago',
  cancelPayment: 'Cancelar pago',
  cancelAndReturnMerchant: 'Cancelar y volver al comerciante',
  cancelAndReturnBack: 'Cancelar y volver',
  returnBackMerchant: 'Volver al comerciante',
  repeatDonation: 'Realizar otra donación',
  paymentAmount: {
    totalFiatAmount: 'Cantidad total:',
    totalCryptoAmount: 'Importe total en criptomoneda:',
    paidFiatAmount: 'Importe pagado:',
    remainFiat: 'Importe restante:',
    remainCrypto: 'Importe restante en criptomoneda:',
    availableFiat: 'Importe disponible:',
    totalTopup: 'Importe recibido:',
    availableTotal: 'Saldo restante:',
    expectedFiatTopup: 'Cantidad esperada:',
    expectedCryptoTopup: 'Cantidad criptomoneda esperada:',
  },
  checkout: {
    title: 'Información de facturación',
    cryptocurrencyPlaceholder: 'Seleccione la criptomoneda',
    payButtonInternal: 'Pagar con el saldo de INXY Payments',
    topup: {
      title: 'Recarga tu saldo para pagar de forma recurrente',
      amountTitle: 'Elija el monto',
      amountTip:
        'Recargar con anticipación no garantiza que tendrás suficiente debido a la volatilidad de las criptomonedas.',
      baseAmount: 'Monto base',
      amountX2: 'Monto x2',
      amountX3: 'Monto x3',
      actionButton: 'Confirmar la cuenta y recargar saldo',
    },
  },
  internalPayment: {
    title: 'Información de pago',
    actionButton: 'Pagar con el saldo de INXY Payments',
    failDescription:
      'El pago ha fallado debido a fondos insuficientes en su billetera o autorización fallida.',
    failActionButton: 'Pagar con otra billetera',
  },
  externalPayment: {
    description: `La dirección de la billetera es para ~una sola transacción~ y tiene un tiempo limitado.
Si envías dinero ~después de que expire el temporizador, tus fondos podrían perderse.~ Para el próximo pago, necesitarás solicitar una nueva factura.`,
    warning:
      'Realiza el pago en la ~moneda y blockchain correctos.~ De lo contrario, tus fondos podrían perderse.',
    timeRemaining: 'Tiempo restante',
    // alert: {
    //   ensureNetworkIs: 'Asegúrese de que la red sea ',
    //   sendOnly: 'Envíe solamente ',
    //   toDepositAddress: ' a esta dirección de depósito',
    //   warning: `Realiza el pago con la moneda y la cadena de bloques correctas.
    //   De lo contrario, tus fondos podrían perderse.`,
    // },
    timeLeftOne: 'Te quedan ',
    timeLeftTwo: ' para enviar los fondos',
    refreshButton: 'Actualizar página',
    availableBalanceNotify:
      'Tienes suficientes fondos en tu saldo. Por favor completa la verificación en dos pasos para realizar un pago',
    footer: {
      heading: '¿Cuánto tardarán los pagos?',
      description:
        'Después de enviar *{crypto}*, debe esperar hasta 10 confirmaciones de la cadena de bloques. En promedio, una confirmación tarda entre 10 y 60 minutos. Tan pronto como se reciba la confirmación, el importe se enviará a su cuenta en un plazo de 30 minutos a 2 horas.',
    },
  },
  merchantInfo: {
    amount: 'Cantidad:',
    frequency: 'Frecuencia:',
    startDate: 'Fecha de inicio:',
    donationAmount: 'Monto de donación:',
    paymentAmount: 'Monto de pago:',
    topupAmount: 'Monto de recarga:',
    balance: 'Saldo actual:',
  },
}
