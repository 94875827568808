import axios from 'axios'

import { RequestInterceptor, ResponseInterceptor } from '@/app/types/api/config'

export default class AxiosFactory {
  static create(
    baseURL: string,
    headers: Record<string, string>,
    timeout: number,
    requestInterceptors?: RequestInterceptor,
    responseInterceptors?: ResponseInterceptor,
  ) {
    const entity = axios.create({
      baseURL,
      headers,
      timeout,
    })

    if (requestInterceptors !== undefined) {
      entity.interceptors.request.use(
        requestInterceptors.onSuccess,
        requestInterceptors.onError,
      )
    }

    if (responseInterceptors !== undefined) {
      entity.interceptors.response.use(
        responseInterceptors.onSuccess,
        responseInterceptors.onError,
      )
    }

    return entity
  }
}
