import {
  SumsubToken,
  VerificationApprovalStatus,
  VerificationResponse,
  VerificationStateResult,
} from '@/app/types/api/resources/verification'
import {
  VerificationActions,
  VerificationGetters,
  VerificationMutations,
  VerificationState,
} from '@/app/types/store/verification'

const state = (): VerificationState => ({
  result: null,
  access_token: null,
  type: null,
  manual_approval_status: VerificationApprovalStatus.Waiting,
})

const mutations: VerificationMutations = {
  setStatus(state: VerificationState, status: VerificationStateResult) {
    state.result = status
  },

  setToken(state: VerificationState, token: null | SumsubToken) {
    state.access_token = token
  },

  setManualApprovalStatus(
    state: VerificationState,
    status: VerificationApprovalStatus,
  ) {
    state.manual_approval_status = status
  },
}

const actions: VerificationActions = {
  updateVerification({ commit }, data: VerificationResponse) {
    commit('setStatus', data.result)
    commit('setToken', data.access_token)
    commit('setManualApprovalStatus', data.manual_approval_status)
  },
}

const getters: VerificationGetters = {
  isOrganizationVerified: (state: VerificationState): boolean => {
    return (
      state.result === VerificationStateResult.Success &&
      state.manual_approval_status === VerificationApprovalStatus.Approved
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
