import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

import env from '@/app/env'

export default (app: App, router: Router) => {
  if (env.buildMode === 'production') {
    Sentry.init({
      app,
      dsn: env.sentryKey,
      environment: env.buildMode,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'https://pay.inxy.io', /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      tracingOptions: { trackComponents: true },
      attachStacktrace: true,
      logErrors: true, // check if needed
    })
  }
}
