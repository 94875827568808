import '@/assets/css/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'

import { Notify } from 'quasar'

export default {
  config: {
    brand: {
      negative: 'var(--clr-error)',
      positive: 'var(--clr-success)',
      warning: 'var(--clr-warning)',
    },
  },
  plugins: {
    Notify,
  },
}
