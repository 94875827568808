import {
  CryptoCurrency,
  Currency,
  ExtendedCryptoCurrency,
} from '@/app/types/app/currency'

export enum MerchantApi {
  GetSession = '/api/v1/sessions/:id',
  GetCryptoPrice = '/api/v1/sessions/:id/price',
  CreateExternalIntent = '/api/v1/payment-intents',
  CancelIntent = '/api/v1/payment-intents/:id/cancel',
  ReserveWallet = '/api/v1/payment-intents/:id/reserve-address',
  CreatePartialPayment = '/api/v1/payment-intents/:id/pay-part',
  CreatePartialTopup = '/api/payment-gateway/v1/subscriptions/:id/deposit-part',
  CreateInternalIntent = '/api/payment-gateway/v1/payment-intents',
  CreateSubscriptionTopupIntent = '/api/payment-gateway/v1/subscriptions/deposit',
  InternalIntentPay = '/api/payment-gateway/v1/payment-intents/:id/pay',
  InternalIntentValidate = '/api/payment-gateway/v1/payment-intents/:id/pay/validate',
}

export enum PaymentStatus {
  AwaitPayment = 'waiting_payment',
  AwaitPaymentPart = 'waiting_payment_part',
  AwaitConfirm = 'waiting_confirmation',
  Canceled = 'canceled',
  Expired = 'expired',
  PartlyPaid = 'partially_paid',
  PartlyPaidExpired = 'partially_paid_expired', // only client-side status
  Paid = 'paid',
  Failed = 'failed',
  Illegal = 'illegal',
}

export enum SessionStatus {
  Pending = 'pending', // no intent
  Active = 'active', // has intent
  Canceled = 'canceled',
  Expired = 'expired',
  Finished = 'finished',
  Illegal = 'illegal',
}

export enum PaymentTopupStatus {
  AwaitPayment = 'waiting_payment',
  AwaitConfirm = 'waiting_confirmation',
  PartlyPaid = 'partially_paid',
  Finished = 'finished',
}

export enum OperationStatus {
  Pending = 'pending',
  Expired = 'expired',
  Finished = 'finished',
  Illegal = 'illegal',
}

export enum MerchantDonateState {
  Forbidden = 'forbidden',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum SessionType {
  Donation = 'donation',
  Order = 'order',
}

export enum PaymentFrequency {
  Single = 'onetime',
  Recurrent = 'recurring',
}

export enum SessionPaymentMode {
  SingleOrder = 'onetime/order',
  SingleDonate = 'onetime/donation',
  RecurrentOrder = 'recurring/order',
  RecurrentDonate = 'recurring/donation',
}

export interface MerchantDetails {
  id: number
  name: string
  description: string
  logo: string
  username: string
  donation_state: MerchantDonateState
}

export interface MerchantCustomer {
  email: string
  first_name: string | null
  last_name: string | null
}

export interface SessionCryptocurrency {
  // amount: string
  cryptocurrency: CryptoCurrency | ExtendedCryptoCurrency
  is_default: boolean
}

export enum SubscriptionInterval {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

export interface PaymentSubscription {
  created_at: string
  fiat_amount: number
  id: number
  interval: SubscriptionInterval
  interval_count: number
  name: string
}

export interface SessionResponse<TAmount = number> {
  merchant: MerchantDetails
  fiat_amount: TAmount
  status: SessionStatus
  customer: MerchantCustomer | null
  order_id?: string
  order_name: string
  success_url: string | null
  cancel_url: string | null
  default_url: string | null
  cryptocurrencies: SessionCryptocurrency[]
  expired_at: string | null
  payment_intent: PaymentIntentResponse | null
  type: SessionType
  payment_type: PaymentFrequency
  subscription: PaymentSubscription | null
  currency: Currency
}

export interface CheckoutFormData extends MerchantCustomer {
  cryptocurrency: SessionCryptocurrency | null
}

export enum CheckoutType {
  Internal = 'internal',
  External = 'external',
}

export interface PaymentIntentPayload {
  session_hash: string
  cryptocurrency: number
  customer?: MerchantCustomer
  fiat_amount?: number
  currency_id?: number
}

export interface PaymentIntentDeposit {
  id: number
  cryptocurrency: CryptoCurrency
  address: string
  reserved_until: string
  created_at: string
  amount: string
  fiat_amount: string
  qr_url: string
}

export interface PaymentIntentSubscriptionDeposit {
  operation: PaymentIntentDeposit | null
  status: PaymentTopupStatus | null
  received_amount: string
  received_fiat_amount: string
  expected_amount: string
  expected_fiat_amount: string
}

export interface PaymentIntentOperation {
  id: number
  status: OperationStatus
  sender: string | null
}

export interface PaymentIntentResponse {
  id: number
  status: PaymentStatus
  customer: MerchantCustomer
  cryptocurrency: CryptoCurrency
  deposit: PaymentIntentDeposit | null
  is_external: boolean
  operations: PaymentIntentOperation[]
  amount: string // crypto total
  fiat_amount: number // fiat total
  paid_amount: string // paid crypto
  paid_fiat_amount: string // paid fiat
  amount_part: string // unpaid crypto amount
  fiat_amount_part: string // unpaid fiat amount
  exchange_rate: string
  deposit_subscription: PaymentIntentSubscriptionDeposit | null
}

export interface CryptoPriceRequestBody {
  cryptocurrency_id: number
}

export interface CryptoPriceResponse {
  amount: string
  cryptocurrency: CryptoCurrency
}
