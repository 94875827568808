import { AxiosError } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config'

import ErrorResponseInterceptorNotify, {
  InterceptorNotifyName,
} from '@/app/api/factories/configs/ErrorResponseInterceptorNotify'
import {
  getErrorMessages,
  getToken,
  setRequestHeaders,
} from '@/app/api/factories/configs/utils'
import env from '@/app/env'
import ClientResponseError from '@/app/errors/ClientResponseError'
import logoutAndReset from '@/app/helpers/logoutAndReset'

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    return setRequestHeaders(config)
  },
  onError(error) {
    return Promise.reject(error)
  },
}

const responseInterceptors: ResponseInterceptor = {
  onSuccess(response) {
    return response
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    if (error.response?.status === HttpStatus.Unauthorized) {
      logoutAndReset()
    }

    const messages = getErrorMessages(error)
    const notify = new ErrorResponseInterceptorNotify(error, messages)

    notify.create()

    const isTwoFactorAuth =
      notify.notifyName === InterceptorNotifyName.TwoFactorAuthorization

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: error.response?.status,
        errors: error.response?.data?.errors ?? null,
        isTwoFactorAuth,
        notify: notify.options,
        messages,
      }),
    )
  },
}

export default {
  baseURL: env.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: 20000,
  requestInterceptors,
  responseInterceptors,
}
