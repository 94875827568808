export default {
  single: {
    order: {
      success: 'Pago efectuado',
      canceled: 'Pago cancelado',
      expired: 'Pago caducado',
    },
    donation: {
      success: 'La donación se ha realizado correctamente!\n¡Gracias!',
      canceled: 'Donación cancelada',
      expired: 'Donación caducada',
    },
  },
  recurring: {
    success: 'Se ha realizado el pago',
    successDescription:
      'Puedes gestionar tus suscripciones desde la página del panel de control de INXY.',
    canceled: 'Pago cancelado',
    expired: 'Pago caducado',
  },
}
