export default {
  messages: {
    copied: 'Copied',
    poweredBy: 'Powered by',
    transactionCreated: 'Transaction successfully created',
    transactionHash: 'Transaction hash',
    successRedirect:
      'Payment Successful! Redirecting back to merchant in 5 seconds.',
    shareVia: 'Share link via',
    or: 'or',
    loggedOut: 'You are successfully logged out',
  },
  labels: {
    address: 'Address',
    blockchain: 'Blockchain',
    coin: 'Coin',
    amount: 'Amount',
    username: 'Username',
    password: 'Password',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    cryptocurrency: 'Cryptocurrency',
    from: 'From',
    to: 'To',
    product: 'Product name',
  },
  buttons: {
    pay: 'Pay',
    cancel: 'Cancel',
    confirm: 'Confirm',
    dismiss: 'Dismiss',
    returnBack: 'Return back',
    continue: 'Continue',
    goToDashboard: 'Go to INXY Dashboard',
    startOver: 'Start over',
    logout: 'Log out',
    copyToClipboard: 'Copy to clipboard',
    contactSupport: 'Contact support',
  },
  pageTitle: {
    checkout: 'Checkout',
    notFound: 'Page not found',
    sessionError: 'Session error',
  },
}
