import { Balance, User, UserStatus } from '@/app/types/api/resources/users'
import { UserState } from '@/app/types/store/store'

export default {
  namespaced: true,
  state: (): UserState => ({
    user: null,
    balances: [],
  }),
  mutations: {
    setUser(state: UserState, user: User) {
      state.user = user
    },

    resetUser(state: UserState) {
      state.user = null
    },

    setBalances(state: UserState, balances: Balance[]) {
      state.balances = balances
    },
  },
  actions: {},
  getters: {
    hasUser: (state: UserState): boolean => !!state.user,
    isEmailVerified: (state: UserState): boolean => {
      return !!state.user?.email_verified_at
    },
    isUserBlocked: (state: UserState): boolean => {
      return state.user?.status === UserStatus.Blocked
    },
    userEmail: (state: UserState): string => state.user?.email ?? '',
    userName: (state: UserState): string => state.user?.username ?? '',
    getCryptoBalance:
      (state: UserState) =>
      (cryptoId: number): Balance | null => {
        const balance = state.balances.find(
          (b) => b.cryptocurrency.id === cryptoId,
        )

        return balance ?? null
      },
  },
}
