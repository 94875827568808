import { SessionResponse } from '@/app/types/api/resources/merchant'
import { Balance, User } from '@/app/types/api/resources/users'
import { OrganizationsState } from '@/app/types/store/organizations'
import { ServiceState } from '@/app/types/store/service'
import { VerificationState } from '@/app/types/store/verification'

export interface GlobalState {
  users: UserState
  session: SessionState
  verification: VerificationState
  service: ServiceState
  organizations: OrganizationsState
}

export interface UserState {
  user: User | null
  balances: Balance[]
}

export interface SessionState {
  sessionData: SessionResponse | null
  sessionId: string
}

export enum StoreCommit {
  SetUser = 'users/setUser',
  ResetUser = 'users/resetUser',
  SetBalances = 'users/setBalances',
  SetSessionData = 'session/setSessionData',
  SetSessionId = 'session/setSessionId',
  SetPartlyPaidExpiredStatus = 'session/setPartlyPaidExpiredStatus',
  SetOrganizations = 'organizations/setOrganizations',
  SetCurrentOrganization = 'organizations/setCurrentOrganization',
}

export enum StoreAction {
  UpdateVerification = 'verification/updateVerification',
}
