export enum RoutePath {
  NotFound = '/404',
  AuthPassRecovery = '/password/recovery',
}

export enum RouteName {
  NotFound = 'not-found',
  SessionProcess = 'session-process',
  ErrorPage = 'error-page',
  Maintenance = 'maintenance',
  AccessRestricted = 'access-restricted',
}
