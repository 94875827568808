export default {
  messages: {
    copied: 'Copiado',
    poweredBy: 'Desarrollado por',
    transactionCreated: 'Transacción creada con éxito',
    transactionHash: 'Hash de transacción',
    successRedirect:
      '¡Pago exitoso! Redirigiendo de vuelta al comerciante en 5 segundos.',
    shareVia: 'Compartir enlace mediante',
    or: 'o',
    loggedOut: 'Has cerrado sesión correctamente',
  },
  labels: {
    address: 'Dirección',
    blockchain: 'Blockchain',
    coin: 'Moneda',
    amount: 'Cantidad',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    firstName: 'Nombre',
    lastName: 'Apellidos',
    email: 'Correo electrónico',
    cryptocurrency: 'Criptomonedas',
    from: 'Desde',
    to: 'Hasta',
    product: 'Nombre del producto',
  },
  buttons: {
    pay: 'Pagar',
    cancel: 'Cancelar',
    confirm: 'Confirme',
    dismiss: 'Descartar',
    returnBack: 'Volver atrás',
    continue: 'Continuar',
    goToDashboard: 'Ir al panel INXY',
    startOver: 'Empezar de nuevo',
    logout: 'Cerrar sesión',
    copyToClipboard: 'Copiar al portapapeles',
    contactSupport: 'Soporte de contacto',
  },
  pageTitle: {
    checkout: 'Proceso de compra',
    notFound: 'Pagina no encontrada',
    sessionError: 'Error de sesión',
  },
}
