import { OrganizationsResponse } from '@/app/types/api/resources/organizations'
import {
  OrganizationsMutationsTree,
  OrganizationsState,
} from '@/app/types/store/organizations'

const state = (): OrganizationsState => ({
  currentOrganizationId: '',
  organizations: [],
})

const mutations: OrganizationsMutationsTree = {
  setOrganizations(
    state: OrganizationsState,
    organizations: OrganizationsResponse[],
  ) {
    state.organizations = organizations
  },
  setCurrentOrganization(state: OrganizationsState, uid: string) {
    state.currentOrganizationId = uid
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
