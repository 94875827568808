export default {
  twoFactorAction: 'Confirm payment',
  cancelPayment: 'Cancel payment',
  cancelAndReturnMerchant: 'Cancel & return back to merchant',
  cancelAndReturnBack: 'Cancel & return back',
  returnBackMerchant: 'Return back to merchant',
  repeatDonation: 'Make another donation',
  paymentAmount: {
    totalFiatAmount: 'Total amount:',
    totalCryptoAmount: 'Total crypto amount:',
    paidFiatAmount: 'Amount paid:',
    remainFiat: 'Remaining amount:',
    remainCrypto: 'Remaining amount in cryptocurrency:',
    availableFiat: 'Available amount:',
    totalTopup: 'Received amount:',
    availableTotal: 'Remaining balance:',
    expectedFiatTopup: 'Expected amount:',
    expectedCryptoTopup: 'Expected crypto amount:',
  },
  checkout: {
    title: 'Billing information',
    cryptocurrencyPlaceholder: 'Select cryptocurrency',
    payButtonInternal: 'Pay with INXY Payments balance',
    // topup
    topup: {
      title: 'Top up your balance to pay recurrently',
      amountTitle: 'Choose amount',
      amountTip:
        "Topping up in advance doesn't guarantee you'll have enough due to volatility of crypto currencies.",
      baseAmount: 'Base amount',
      amountX2: 'Amount x2',
      amountX3: 'Amount x3',
      actionButton: 'Confirm account and top up',
    },
  },
  internalPayment: {
    title: 'Payment information',
    actionButton: 'Pay with INXY balance',
    failDescription:
      'Payment failed due to insufficient funds on your wallet or unsuccessful authorization.',
    failActionButton: 'Pay with another wallet',
  },
  externalPayment: {
    description: `The wallet address is for ~one transaction only~ and limited in time.\nIf you will send money ~after the timer expires, your funds may be lost.~ For the next payment, you will need to request a new invoice.`,
    warning:
      'Make the payment in the ~correct coin and blockchain.~ Otherwise, your funds may be lost.',
    timeRemaining: 'Time remaining',
    // alert: {
    //   ensureNetworkIs: 'Ensure the network is ',
    //   sendOnly: 'Send only ',
    //   toDepositAddress: ' to this deposit address',
    //   warning: `Make the payment in the correct coin and blockchain.
    //     Otherwise, your funds may be lost.`,
    // },
    timeLeftOne: 'You have ',
    timeLeftTwo: ' left to send funds',
    refreshButton: 'Refresh page',
    availableBalanceNotify:
      'You have enough funds on your balance. Please complete Two-step verification to make a payment',
    footer: {
      heading: 'How long will payments take?',
      description:
        'After sending *{crypto}*, you should wait for up to 10 confirmations of the blockchain. On average, one confirmation takes 10-60 minutes. As soon as the confirmation is received, amount will be sent to your account within 30 min - 2 hours.',
    },
  },
  merchantInfo: {
    amount: 'Amount:',
    frequency: 'Frequency:',
    startDate: 'Start date:',
    donationAmount: 'Donation amount:',
    paymentAmount: 'Payment amount:',
    topupAmount: 'Top up amount:',
    balance: 'Current balance:',
  },
}
