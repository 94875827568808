import { AxiosRequestConfig, AxiosResponse } from 'axios'

export type BackendResponse<T> = Promise<AxiosResponse<T>>

export type DictionaryResponse<T> = AxiosResponse<{ data: T }>

export interface ErrorResponse {
  message: string
  errors?: Record<string, string[]>
}

export interface RequestInterceptor {
  onSuccess: (requestConfig: AxiosRequestConfig) => AxiosRequestConfig
  onError: (error: any) => any
}

export interface ResponseInterceptor {
  onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>
  onError: (error: any) => any
}

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  ServiceUnavailable = 503,
}

export enum CryptoNetworksStatus {
  Success = 200,
  RequestInitialized = 4000,
  NoChain = 4902,
  UserDenyRequest = 4001,
  UserRejectTransaction = -32000,
  NoAddress = -32602,
  NonContractAddressOrBlacklisted = -32603,
  PermissionsPending = -32002,
  ActionRejected = 'ACTION_REJECTED',
  UnexpectedGasLimit = 'UNPREDICTABLE_GAS_LIMIT',
}

export enum TwoFactorAuth {
  Header = 'X-INXY-Payments-2FA-Code',
  ConfirmationMessage = 'need two factor auth',
}

export enum RequestHeaders {
  OrganizationId = 'X-Organization-Uid',
  Localization = 'X-Localization',
  ProjectName = 'X-Project',
  PagePath = 'X-Page-Path',
  PageRequestId = 'X-Page-Request-ID',
}
