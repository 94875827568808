import { Notify } from 'quasar'

import { GtmEvent } from '@/app/types/app/event'
import { StoreCommit } from '@/app/types/store/store'

import cookies from '@/plugins/cookies'
import i18n from '@/plugins/i18n'
import Store from '@/store'

import env from '@/app/env'

export default function () {
  cookies.remove(env.cookieName, '/', env.host)

  window.dataLayer?.push({ event: GtmEvent.Logout })

  Store.commit(StoreCommit.ResetUser)

  Notify.create({
    message: i18n.global.t('common.messages.loggedOut'),
    type: 'positive',
  })

  return
}
