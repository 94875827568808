export default {
  standardServerError:
    'Ha ocurrido un error. Recarga la página e inténtalo de nuevo.',
  required: 'Este campo no puede estar vacío.',
  emailTemplate:
    'Por favor, introduce una dirección de correo electrónico válida.',
  passTemplate:
    'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una minúscula y un número.',
  usernameTemplate:
    'Este nombre de usuario es inválido. Debe tener entre 5 y 15 símbolos de longitud y no puede contener caracteres especiales, excepto el guión bajo.',
  minSymbols: 'Mínimo {symbol} símbolos.',
  maxSymbols: 'Máximo {symbol} símbolos.',
  integers: 'Sólo se permiten números enteros.',
  topupAmount: 'Elige el monto del depósito.',
  notEnoughFunds: 'Fondos insuficientes en su cuenta',
  wrongCoinType: 'Tipo de moneda incorrecto. No se puede enviar la transacción',
  requestWasDenied: 'Estamos utilizando otra red. Por favor, cambie de red.',
  transactionDenied: 'La transacción fue denegada por consumidor.',
  transactionRejected:
    'La transacción fue rechazada por razones desconocidas. Por favor, inténtelo de nuevo más tarde.',
  permissionsPending: 'Actualmente tiene abierta una ventana activa de MetaMask. Cierre su ventana activa de MetaMask o continúe usándola. Gracias.',
  nonceTooLow: {
    message: 'Nonce es demasiado bajo, intente cambiarlo manualmente. Sigue el próximo',
    linkLabel: 'artículo.',
  },
  tronLinkLocked: 'TronLink bloqueado. Por favor Iniciar sesión',
  tronLinkNotFound: 'Para usar esta opción instale la extensión TronLink',
  tronAddressNotFound: 'No se encontró la dirección de Tron',
  contractNotFound: 'Contrato no encontrado',
  networkNotSupported: 'La red seleccionada {userNetwork} no es compatible. Por favor, seleccione {network}',
  paymentOptionUnavailable: "Lo sentimos, esta opción de pago no está disponible actualmente para su cuenta.Ага,",
}
