export enum I18NLocale {
  Es = 'es',
  En = 'en',
}

export enum I18NLanguageName {
  English = 'English',
  Spanish = 'Español',
}

export interface I18NLanguage {
  code: I18NLocale
  name: I18NLanguageName
}
