import { StoreCommit } from '@/app/types/store/store'

import Store from '@/store'

import organizationsApi from '@/app/api/resources/organizations'

export default class {
  fetchOrganizations() {
    return organizationsApi.fetchOrganizations().then(({ data }) => {
      Store.commit(StoreCommit.SetOrganizations, data.data)
      Store.commit(StoreCommit.SetCurrentOrganization, data.data[0]?.uid ?? '')

      return Promise.resolve()
    })
  }
}
