import { AxiosResponse } from 'axios'

import {
  AuthFormData,
  AuthResponse,
  BalanceParams,
  SignupFormData,
} from '@/app/types/api/resources/users'
import { GtmEvent } from '@/app/types/app/event'
import { StoreCommit } from '@/app/types/store/store'

import cookies from '@/plugins/cookies'
import Store from '@/store'

import usersApi from '@/app/api/resources/users'
import env from '@/app/env'
import MoneyFormat from '@/app/formatters/MoneyFormat'

export default class {
  fetchUser() {
    return usersApi
      .fetchMe()
      .then(({ data }) => Store.commit(StoreCommit.SetUser, data.user))
  }

  fetchBalances(params?: BalanceParams) {
    return usersApi.fetchBalances(params).then(({ data }) => {
      const balances = data.data.map((balance) => ({
        ...balance,
        amount: MoneyFormat.parseFloat(balance.amount),
        fiatAmount: MoneyFormat.parseFloat(balance.fiatAmount),
        pendingReceived: MoneyFormat.parseFloat(balance.pendingReceived),
      }))

      Store.commit(StoreCommit.SetBalances, balances)
    })
  }

  #setAuthToken(token: string) {
    cookies.set(env.cookieName, token, '7d', '/', env.host)
  }

  login(data: AuthFormData) {
    return usersApi
      .login(data)
      .then(({ data }: AxiosResponse<AuthResponse>) => {
        this.#setAuthToken(data.access_token)

        Store.commit(StoreCommit.SetUser, data.user)

        window.dataLayer?.push({
          event: GtmEvent.Login,
          analytics_id: data.user.analytics_id,
        })
      })
  }

  signup(data: SignupFormData) {
    return usersApi
      .signup(data)
      .then(({ data }: AxiosResponse<AuthResponse>) => {
        this.#setAuthToken(data.access_token)

        Store.commit(StoreCommit.SetUser, data.user)

        window.dataLayer?.push({
          event: GtmEvent.Signup,
          analytics_id: data.user.analytics_id,
        })
      })
  }
}
