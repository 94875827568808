import './assets/css/tailwind.css'
import './assets/css/variables.css'
import './assets/scss/entry.scss'
import './assets/scss/buttons.scss'
import './assets/scss/notify.scss'

import { Quasar } from 'quasar'
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'

import App from './App.vue'
import quasarUserOptions from './app/quasar/quasar-user-options'
import i18n from './plugins/i18n'
import initSentry from './plugins/sentry'
import initSockets from './plugins/sockets'
import router from './router'
import store from './store'

const app = createApp(App)

const initDevicejs = () => import('@/plugins/devicejs')

initSentry(app, router)

initDevicejs()
initSockets()

app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createMetaManager())
  .mount('#app')
