import { createI18n } from 'vue-i18n'

import { I18NLocale } from '@/app/types/app/i18n'

import en from '@/lang/en'
import es from '@/lang/es'

export default createI18n({
  locale: I18NLocale.En,
  fallbackLocale: I18NLocale.En,
  allowComposition: true,
  messages: { en, es },
  legacy: false,
})
