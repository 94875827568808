export const regExPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
export const regExUsername = /^([a-z0-9]|[_]){5,15}$/

export const regExTrailingZeros = /([.,]0+$)|(0+$)/g
export const regExWhiteSpace = /\s/g
export const regExInteger = /^\d*([.,]\d+)?$/
export const regExMinLength = /^.{8,}$/
export const regExDigit = /^(?=.*\d).+$/
export const regExUppercase = /^(?=.*[A-Z]).+$/
export const regExLowercase = /^(?=.*[a-z]).+$/
