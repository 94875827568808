import { HttpStatus } from '@/app/types/api/config'
import { ServiceGettersTree, ServiceMutationsTree, ServiceState } from '@/app/types/store/service'

const state = (): ServiceState => ({
  serverStatus: null,
})

const getters: ServiceGettersTree = {
  isServerStatusFetched: (state: ServiceState): boolean => {
    return state.serverStatus !== null
  },

  isServerMaintaining: (state: ServiceState): boolean => {
    return state.serverStatus === HttpStatus.ServiceUnavailable
  },
}

const mutations: ServiceMutationsTree = {
  changeServerStatus(state: ServiceState, status: HttpStatus) {
    state.serverStatus = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
