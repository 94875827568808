export default {
  single: {
    order: {
      awaiting: 'Esperando el pago',
      processing: {
        name: 'Procesando',
        title: 'Se está procesando tu pago.',
        description:
          'Has pagado por esta compra, pero necesitamos un poco de tiempo para procesarla.',
      },
      partial: {
        name: 'Pago parcial',
        title: 'Este pago se realizó solo parcialmente.',
        description:
          'Por favor, transfiere el monto restante para un pago completo o comunícate directamente con tu comerciante.',
        warning:
          'NO VUELVAS A ENVIAR DINERO A TU BILLETERA ANTERIOR, ESTO RESULTARÁ EN SU PÉRDIDA.',
        actionButton: 'Paga el monto restante',
      },
      success: {
        name: 'Exito',
        title: 'Tu pago se procesó con éxito.',
        description: '¡Gracias por la compra!',
      },
      cancelled: {
        name: 'Cancelado',
        title: '¡Tu pago ha sido cancelado!',
      },
      expired: {
        name: 'Expirado',
        title: '¡Se ha acabado el tiempo!',
        description:
          'El tiempo de tu sesión se ha agotado y el pago ha fallado. Puedes volver a la página del comerciante y realizar el pago de nuevo.',
      },
      partialExpired: {
        name: 'Expirado',
        title: '¡Este pago se realizó parcialmente, pero se agotó el tiempo!',
        description:
          'El tiempo de tu sesión se ha agotado y el pago ha fallado.\nPero como se pagó parcialmente, por favor comunícate directamente con tu comerciante\npara obtener más instrucciones.',
      },
      failed: {
        name: 'Fallido',
        title: '¡Ocurrió un error durante el pago!',
        description:
          'Por favor, comunícate directamente con tu comerciante para obtener más instrucciones.',
      },
      illegal: {
        name: 'Ilegal',
        title: 'Cerrado. Pago Ilegal',
        description:
          'Transacción marcada durante la revisión KYT. Los fondos han sido congelados en espera de una investigación adicional.',
      },
    },
    donation: {
      processing: {
        title: 'Se está procesando tu donación.',
        description:
          'Has realizado una donación, pero necesitamos un poco de tiempo para procesarla.',
      },
      success: {
        title: 'Tu donación se procesó con éxito.',
        description: '¡Gracias por la donación!',
      },
      cancelled: {
        title: '¡Tu donación ha sido cancelada!',
      },
      expired: {
        title: '¡Se ha acabado el tiempo!',
        description:
          'El tiempo de tu sesión se ha agotado y el pago ha fallado.\nPuedes volver a la página de donación y realizar el pago de nuevo.',
      },
      illegal: {
        name: 'Ilegal',
        title: 'Cerrado. Pago Ilegal',
        description:
          'Transacción marcada durante la revisión KYT. Los fondos han sido congelados en espera de una investigación adicional.',
      },
    },
  },
  recurring: {
    success: {
      title: '¡Tu saldo se ha recargado con éxito!',
      description:
        'El pago se ha realizado.\nPuedes gestionar tus suscripciones desde la página del panel de INXY.',
    },
  },
}
