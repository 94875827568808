import {
  PaymentStatus,
  SessionResponse,
} from '@/app/types/api/resources/merchant'
import { SessionState } from '@/app/types/store/store'

export default {
  namespaced: true,

  state: (): SessionState => ({
    sessionData: null,
    sessionId: '',
  }),
  mutations: {
    setSessionData(state: SessionState, sessionData: SessionResponse) {
      state.sessionData = sessionData
    },

    setSessionId(state: SessionState, sessionId: string) {
      state.sessionId = sessionId
    },

    setPartlyPaidExpiredStatus(state: SessionState) {
      const data = { ...state.sessionData } as SessionResponse

      // set client-side status
      data!.payment_intent!.status = PaymentStatus.PartlyPaidExpired

      state.sessionData = data
    },
  },

  actions: {},

  getters: {
    getSessionCurrencyId: (state: SessionState): number =>
      state.sessionData?.currency?.id ?? 0,
  },
}
