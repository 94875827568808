import { RouteRecordRaw } from 'vue-router'

import { RouteName } from '@/app/types/app/routes'

import i18n from '@/plugins/i18n'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale?',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        component: () => import('@/layouts/DefaultLayout.vue'),
        children: [
          {
            path: 'sessions/:session_id',
            name: RouteName.SessionProcess,
            component: () => import('@/views/ViewSessionProcess.vue'),
          },
        ],
      },
      {
        path: '',
        component: () => import('@/layouts/LayoutEmpty.vue'),
        children: [
          {
            path: 'error',
            name: RouteName.ErrorPage,
            component: () => import('@/views/ServiceViews/ViewError.vue'),
            meta: { canLogout: true },
          },
          {
            path: 'maintenance',
            name: RouteName.Maintenance,
            component: () => import('@/views/ServiceViews/ViewMaintenance.vue'),
          },
          {
            path: 'access-restricted',
            name: RouteName.AccessRestricted,
            component: () => import('@/views/ServiceViews/ViewUserBlocked.vue'),
            meta: { canLogout: true },
          },
          {
            path: '404',
            name: RouteName.NotFound,
            component: () => import('@/views/ServiceViews/ViewNotFound.vue'),
            meta: { canLogout: true },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    redirect: {
      name: RouteName.NotFound,
      params: { locale: i18n.global.locale.value },
    },
  },
]

export default routes
