import accessRestricted from '@/lang/es/modules/accessRestricted'
import common from '@/lang/es/modules/common'
import errorPage from '@/lang/es/modules/errorPage'
import errors from '@/lang/es/modules/errors'
import externalStatuses from '@/lang/es/modules/externalStatuses'
import internalStatuses from '@/lang/es/modules/internalStatuses'
import maintenance from '@/lang/es/modules/maintenance'
import modals from '@/lang/es/modules/modals'
import notFound from '@/lang/es/modules/notFound'
import session from '@/lang/es/modules/session'

export default {
  common,
  errors,
  modals,
  errorPage,
  session,
  internalStatuses,
  externalStatuses,
  notFound,
  maintenance,
  accessRestricted,
}
