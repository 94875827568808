export enum AppEvent {
  Submit = 'submit',
  Fail = 'fail',
  Success = 'success',
  Cancel = 'cancel',
  Expired = 'expired',
  WalletChange = 'wallet-change',
  Close = 'close',
  Switch = 'switch',
  Choose = 'choose',
  Auth = 'auth',
  Topup = 'topup',
}

export enum ModelEvent {
  UpdateModelValue = 'update:modelValue',
  UpdateModalVisibility = 'update:modalVisibility',
}

export enum EventPayload {
  Forced = 'forced',
}

export enum GtmEvent {
  Logout = 'logout',
  Login = 'login',
  Signup = 'registration',
  TronTransactionSigned = 'tronlink_transaction_signed',
  SessionInit = 'payment_gateway.session.activate',
  MetamaskSuccess = 'metamask_transaction_signed',
}

export enum NativeEvent {
  Blur = 'blur',
}
